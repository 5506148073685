<script setup>
import Cookies from "js-cookie";
import subUserRedirect from "@/utils/subUserRedirect";

import {
  computed,
  ref,
  nextTick,
  onMounted,
  watch,
  onUnmounted,
  provide,
} from "vue";

import { useRoute, useRouter } from "vue-router/composables";

import { useStore } from "@/utils/useStore";

import {
  GrSidebar,
  GrSideHeader,
  GrSideItem,
  GrSideItemDrop,
  iconSideDashboard,
  iconSideSales,
  iconSideProducts,
  iconSideFinancial,
  iconSideReports,
  iconSideSystem,
  iconSideRewards,
} from "@/components/GrComponents";

import GrSidebarHeaderRight from "@/components/Side/GrSideBar/GrSidebarHeaderRight.vue";
import GrSidebarHeaderLeft from "@/components/Side/GrSideBar/GrSidebarHeaderLeft.vue";

const route = useRouter();
const router = useRoute();
const store = useStore();

const headerHeight = ref(0);
const headerClientHeight = ref(0);
const headerMarginTop = ref(0);
const headerMarginBottom = ref(0);

const GrSideHeaderRef = ref(null);
const GrSidebarRef = ref(null);

const hoverInSideBar = ref(false);
const hovered = ref(false);
const activeSidebar = ref(false);
const browsingByKey = ref(false);

const isMobile = ref(false);

const dashboard = ref(false);

const activeItems = ref({
  Vendas: false,
  Produtos: false,
  Financeiro: false,
  Marketing: false,
  Recompensas: false,
  Sistema: false,
});

const openItems = ref({
  Vendas: false,
  Produtos: false,
  Financeiro: false,
  Marketing: false,
  Recompensas: false,
  Sistema: false,
});

const routesSales = ref([
  {
    name: "Minhas Vendas",
    translation: "side.text_1965",
    route: "Sales",
    active: false,
    item: "Vendas",
    access: true,
  },
  {
    name: "Meus Contratos",
    translation: "side.text_1966",
    route: "Contracts",
    active: false,
    item: "Vendas",
    access: true,
  },
  {
    name: "Afiliados",
    translation: "side.text_1960",
    route: "Affiliation",
    active: false,
    item: "Vendas",
    access: true,
  },
  {
    name: "Leads",
    translation: "side.text_1977",
    route: "Leads",
    active: false,
    item: "Vendas",
    access: true,
  },
  {
    name: "Reclamações",
    translation: "side.text_1962",
    route: "Reclamações",
    active: false,
    item: "Vendas",
    access: true,
  },
  {
    name: "Oportunidades",
    translation: "side.text_2004",
    route: "Opportunities",
    active: false,
    item: "Vendas",
    access: false,
  },
]);

const routesProducts = ref([
  {
    name: "Novo produto",
    translation: "side.text_1978",
    route: "CreateProductTypes",
    active: false,
    item: "Produtos",
    access: true,
  },
  {
    name: "Meus produtos",
    translation: "side.text_1979",
    route: "Products",
    active: false,
    item: "Produtos",
    access: true,
  },
  {
    name: "Área de membros",
    translation: "side.text_1980",
    route: "",
    active: false,
    item: "Produtos",
    access: true,
  },
  {
    name: "Cupons",
    translation: "side.text_1981",
    route: "Coupon",
    active: false,
    item: "Produtos",
    access: true,
  },
  {
    name: "Links",
    translation: "side.text_1955",
    route: "Links",
    active: false,
    item: "Produtos",
    access: true,
  },
  {
    name: "Checkouts",
    translation: "side.text_1959",
    route: "Checkouts",
    active: false,
    item: "Produtos",
    access: true,
  },
  {
    name: "Afiliações",
    translation: "side.text_1982",
    route: "Affiliation",
    query: "affiliation-products",
    active: false,
    item: "Produtos",
    access: true,
  },
]);

const routesFinancial = ref([
  {
    name: "Extrato",
    translation: "side.text_1956",
    route: "Statements",
    active: false,
    item: "Financeiro",
    access: true,
  },
  {
    name: "Sacar dinheiro",
    translation: "side.text_1983",
    route: "Statements",
    query: "withdrawal-request",
    active: false,
    item: "Financeiro",
    access: true,
  },
  {
    name: "Meus Saques",
    translation: "side.text_1984",
    route: "Statements",
    query: "my-withdrawal",
    active: false,
    item: "Financeiro",
    access: true,
  },
  {
    name: "Antecipações",
    translation: "side.text_1985",
    route: "Statements",
    query: "anticipations",
    active: false,
    item: "Financeiro",
    access: true,
  },
  {
    name: "Contas bancárias",
    translation: "side.text_1986",
    route: "Statements",
    query: "bank-accounts",
    active: false,
    item: "Financeiro",
    access: true,
  },
  {
    name: "Emissões de NF",
    translation: "side.text_1987",
    route: "NoteCenter",
    active: false,
    item: "Financeiro",
    access: true,
  },
]);

const routesRewards = ref([
  {
    name: "Greenn Partner",
    translation: "side.text_2002",
    route: "Indicacoes",
    active: false,
    item: "Recompensas",
    access: true,
  },
  {
    name: "Embaixador",
    translation: "side.text_2001",
    route: "Ambassador",
    active: false,
    item: "Recompensas",
    access: true,
  },
]);

const routesSystem = ref([
  {
    name: "Controle de acesso",
    translation: "side.text_1991",
    route: "Settings",
    query: "access-control",
    active: false,
    item: "Sistema",
    access: true,
  },
  {
    name: "Tokens e Api Key",
    translation: "side.text_1992",
    route: "Settings",
    query: "Tokens-and-API",
    active: false,
    item: "Sistema",
    access: true,
  },
]);

const allRoutes = ref([
  ...routesSales.value,
  ...routesProducts.value,
  ...routesFinancial.value,
  ...routesRewards.value,
  ...routesSystem.value,
]);

const ambassador = computed(() => {
  return !!store.state.user.user.is_ambassador;
});

const indication = computed(() => {
  return store.getters.getIndication;
});

const flagOpportunities = computed(() => {
  return store.getters['getFlags'].includes('opportunity_settings')
})

const isClient = computed(() => {
  return store.getters["getIsClient"];
});

const isEmptySales = computed(() => {
  return routesSales.value.every((routeItem) => !routeItem.access);
});

const isEmptyProducts = computed(() => {
  return routesProducts.value.every((routeItem) => !routeItem.access);
});

const isEmptyFinancial = computed(() => {
  return routesFinancial.value.every((routeItem) => !routeItem.access);
});

const isEmptyRewards = computed(() => {
  return routesRewards.value.every((routeItem) => !routeItem.access);
});

const isEmptySystem = computed(() => {
  return routesSystem.value.every((routeItem) => !routeItem.access);
});

const activeDashboard = computed(() => router.name === "Dashboard");
const activeRelatorio = computed(() => router.name === "reports");

const headerElement = computed(() => GrSideHeaderRef.value?.header);

const totalHeight = computed(() => {
  const height = Math.max(headerHeight.value, headerClientHeight.value);
  return height + headerMarginTop.value + headerMarginBottom.value;
});

const distanceFromTop = computed(() => {
  return `${onResizeHeigth()}px`;
});

const isActiveDashboard = computed(() => {
  const currentRoute = router.name;

  if (currentRoute === "Dashboard") {
    return false;
  }

  return activeItems.value["Vendas"];
});

watch(store.state.user, async (newuser) => {
  if (newuser.user.type === "CLIENT") {
    await store.dispatch("updateIsClient", true);
  }
});

watch(router, async (to) => {
  const currentRoute = router.name;

  if (to.name === "Dashboard") {
    allRoutes.value.forEach((route) => {
      route.active = false;
    });
    assignValueInArray(activeItems, false);
    assignValueInArray(openItems, false);

    setTimeout(() => {
      if (currentRoute === "Dashboard") {
        activeItems.value["Vendas"] = true;
        openItems.value["Vendas"] = true;
        return;
      }
    }, 100);
  } else if (to.name === "Affiliation") {
    if (to?.query?.action === "affiliation-products") {
      const itemRouteAffiliation = routesProducts.value.find(
        (item) => item.route === "Affiliation"
      );
      if (itemRouteAffiliation) {
        assignValueInArray(activeItems, false);
        assignValueInArray(openItems, false);

        setSubItemActive(itemRouteAffiliation);
        setItemActive("Produtos");
      }

      return;
    } else {
      const itemRouteAffiliation = routesSales.value.find(
        (item) => item.route === "Affiliation"
      );
      if (itemRouteAffiliation) {
        assignValueInArray(activeItems, false);
        assignValueInArray(openItems, false);

        setSubItemActive(itemRouteAffiliation);
        setItemActive("Vendas");
      }
    }
    return;
  } else {
    initializeActiveState();
  }
});

watch(totalHeight, async () => {
  requestAnimationFrame(updateHeaderMeasurements);
});

watch(
  activeSidebar,
  (newVal) => {
    if (!newVal) {
      closeSideMenu();
    }
  },
  { deep: true }
);

watch(
  openItems,
  (newVal) => {
    const existOpenItemKey = Object.keys(newVal).find((key) => newVal[key]);
    if (existOpenItemKey && hovered.value === false) {
      openItems.value[existOpenItemKey] = false;
    }
  },
  { deep: true }
);

async function fetchPermissions() {
  if (store.getters.setPanel) {
    store
      .dispatch("setPermissions")
      .then(async (response) => {
        Cookies.set("setPanelPermission", JSON.stringify(response), {
          expires: 7,
        });
        const routeName = subUserRedirect(response)
        if (routeName.route !== "dashboard") {
          route.push(routeName.route);
        }
        await getPermissions();
      })
      .catch((err) => {
        console.error(err);
      });
    return;
  } else {
    await getPermissions();
  }
}

async function getPermissions() {
  return new Promise(async (resolve) => {
    if (store.getters.setPanel) {
      let dados = Cookies.get("setPanelPermission");
      if (dados) {
        dados = JSON.parse(dados);

        if (dados.find((x) => x.name === "DashboardRead")) {
          dashboard.value = true;
        }

        const routePermissions = {
          Sales: "SaleRead",
          Contracts: "ContractRead",
          Leads: "LeadRead",
          Reclamações: "ClaimRead",
          CreateProductTypes: "ProductCreate",
          Products: "ProductRead",
          Coupon: "CouponRead",
          Links: "LinkRead",
          Checkouts: "CustomCheckoutRead",
          NoteCenter: "CenterNotasRead",
        };

        const panel = store.getters.setPanel;

        const routesSubDoesNotAcess = {
          Statements: panel ? false : true,
        };

        const hasAccess = (permissionName) => {
          return (
            (!panel ||
              (panel && !!dados.find((x) => x.name === permissionName))) &&
            !isClient.value
          );
        };

        allRoutes.value.forEach((routeItem) => {
          const permissionName = routePermissions[routeItem.route];
          const NotPermissionName = routesSubDoesNotAcess[routeItem.route];

          if (permissionName) {
            routeItem.access = hasAccess(permissionName);
          }

          if (routeItem.name === "Área de membros") {
            routeItem.access = hasAccess("EditProductIntegrations");
          }

          if (
            routeItem.name === "Afiliações" ||
            routeItem.name === "Afiliados"
          ) {
            routeItem.access = !!panel ? false : true;
          }

          if (routeItem.name === "Greenn Partner") {
            routeItem.access = indication.value;
          }

          if (routeItem.name === "Embaixador") {
            routeItem.access = ambassador.value;
          }

          if (routeItem.item === "Financeiro") {
            if (routeItem.name === "Emissões de NF") {
              routeItem.access = hasAccess(permissionName);
              return;
            }
            routeItem.access = NotPermissionName;
          }
        });
      }
      resolve(true);
      return;
    }

    allRoutes.value.forEach((routeItem) => {
      routeItem.access = true;

      if (routeItem.name === "Greenn Partner") {
        routeItem.access = indication.value;
      }

      if (routeItem.name === "Embaixador") {
        routeItem.access = ambassador.value;
      }
      if (routeItem.name === "Oportunidades") {            
        routeItem.access = flagOpportunities
      }
    });
    resolve(true);
  });
}

/**
 * Define o estado ativo de um subitem.
 *
 * @param subItem - O sub-item a ser definido como ativo.
 */
function setSubItemActive(subItem) {
  // Se o nome da rota corresponder ao nome do subItem. Caso contrário, define como false.
  allRoutes.value.forEach((route) => {
    route.active = route.name === subItem.name;
  });

  // Define todos os itens do menu como false
  assignValueInArray(activeItems, false);

  // Define todos os itens do sub-menu como false e envia qual está ativo
  assignValueInArray(openItems, false, false, subItem.item);

  // Verifica se o subItem pertence a algum grupo. Se sim, define como ativo.
  if (routesSales.value.some((route) => route.name === subItem.name)) {
    activeItems.value.Vendas = true;
  } else if (
    routesProducts.value.some((route) => route.name === subItem.name)
  ) {
    activeItems.value.Produtos = true;
  } else if (
    routesFinancial.value.some((route) => route.name === subItem.name)
  ) {
    activeItems.value.Financeiro = true;
  } else if (routesRewards.value.some((route) => route.name === subItem.name)) {
    activeItems.value.Recompensas = true;
  } else if (routesSystem.value.some((route) => route.name === subItem.name)) {
    activeItems.value.Sistema = true;
  }

  // Se o usuário estava navegando por meio do teclado, redefine esses estados.
  if (browsingByKey.value) {
    browsingByKey.value = false;
    // Verifica se o cursor esta na side
    if (!hoverInSideBar.value) {
      hovered.value = false;
      activeSidebar.value = false;

      // Define todos os itens do sub-menu como false.
      assignValueInArray(openItems, false);
    }
  }
}

/**
 * Define o estado ativo de um item de menu.
 *
 * @param selectedMenu - O item do menu a ser definido como ativo.
 * @param navigateForKey - Se a navegação foi acionada pelo pressionamento de uma tecla.
 */
async function setItemActive(selectedMenu, navigateForKey) {
  if (navigateForKey) {
    browsingByKey.value = true;
    hovered.value = true;
    activeSidebar.value = true;
  }

  // Coletar todos os itens de rotas ativas
  const activeSubRouteItems = new Set(
    allRoutes.value.filter((route) => route.active).map((route) => route.item)
  );

  // Identificar itens que estão ativos, mas não possuem sub-rotas ativas
  const notActiveSubRouteItems = Object.keys(activeItems.value).filter(
    (key) => {
      return activeItems.value[key] && !activeSubRouteItems.has(key);
    }
  );

  // Se o item selecionado já está ativo e possui sub-rotas ativas, não fazer nada
  if (activeSubRouteItems.has(selectedMenu)) {
    openItems.value[selectedMenu] = true;
    return;
  }

  // Desativar itens que não possuem sub-rotas ativas
  for (const key of notActiveSubRouteItems) {
    activeItems.value[key] = false;
    openItems.value[key] = false;
  }

  // Alternar o estado do item selecionado
  const isSelectedMenuActive = activeItems.value[selectedMenu];
  activeItems.value[selectedMenu] = !isSelectedMenuActive;
  openItems.value[selectedMenu] = !isSelectedMenuActive;

  // Se o item selecionado estiver na lista de notActiveSubRouteItems, desativá-lo
  if (notActiveSubRouteItems.includes(selectedMenu)) {
    activeItems.value[selectedMenu] = false;
    openItems.value[selectedMenu] = false;
  }
}

/**
 * Atribui valores aos itens em um objeto Ref<ActiveItems>.
 *
 * @param array - O objeto Ref<ActiveItems> cujos valores serão atribuídos.
 * @param value - O valor booleano a ser atribuído aos itens.
 * @param hasIndice - Opcional. Se verdadeiro, só alterará os valores das chaves que não estão em activeRouteItems.
 * @param name - Opcional. O nome da chave específica que, se fornecida e encontrada, será definida como true.
 */
function assignValueInArray(array, value, hasIndice = false, name = null) {
  // Conjunto de itens de rota ativa
  const activeRouteItems = new Set(
    allRoutes.value.filter((route) => route.active).map((route) => route.item)
  );
  // Itera sobre as chaves do objeto recebido
  for (const key in array.value) {
    // Verifica se a chave é propriedade do objeto array.value
    if (Object.prototype.hasOwnProperty.call(array.value, key)) {
      // Se hasIndice for verdadeiro, só altera os valores das chaves que não estão em activeRouteItems
      if (hasIndice) {
        if (!activeRouteItems.has(key)) {
          array.value[key] = false;
        } else {
          array.value[key] = true;
        }
      } else {
        // Atribui o valor especificado a todas as chaves
        array.value[key] = value;
        // Se um nome for fornecido e a chave corresponder ao nome, define essa chave como true
        if (name && key === name) {
          array.value[key] = true;
        }
      }
    }
  }
}

function closeSideMenu() {
  hovered.value = false;
  // Define todos os itens do menu como false.
  assignValueInArray(openItems, false);
  // Define todos os itens do sub-menu como false, exeto o valore que não está ativo.
  assignValueInArray(activeItems, false, true);

  if (hoverInSideBar.value && window.innerWidth >= 768) {
    activeSidebar.value = false;
  }

  const currentRoute = router.name;

  if (currentRoute === "Dashboard") {
    setItemActive("Vendas");
  }
}

function openSideMenu() {
  hovered.value = true;

  const currentRoute = router.name;

  if (currentRoute === "Dashboard") {
    openItems.value["Vendas"] = true;
    return;
  }
  // Define apenas o que tem sub-menu ativo.
  assignValueInArray(openItems, true, true);
}

function toogleSide() {
  if (activeSidebar.value === true) {
    hoverInSideBar.value = false;
    activeSidebar.value = false;
    closeSideMenu();
    return;
  }
  hoverInSideBar.value = true;
  activeSidebar.value = true;
  openSideMenu();
}

/**
 * Inicializa o estado ativo dos itens de menu com base na rota atual.
 */
function initializeActiveState() {
  const currentRoute = router.name;

  if (currentRoute === "Dashboard") {
    setItemActive("Vendas");
  }

  if (currentRoute === "MyAccount" || currentRoute === "MyPurchases") {
    assignValueInArray(activeItems, false);
    assignValueInArray(openItems, false);

    allRoutes.value.forEach((route) => {
      route.active = false;
    });

    return;
  }

  allRoutes.value.forEach((route) => {
    if (route.route === currentRoute) {
      if (currentRoute === "Affiliation") {
        if (router?.query?.action === "affiliation-products") {
          const itemRouteAffiliation = routesProducts.value.find(
            (item) => item.route === "Affiliation"
          );
          if (itemRouteAffiliation) {
            assignValueInArray(activeItems, false);
            assignValueInArray(openItems, false);
            setSubItemActive(itemRouteAffiliation);
            setItemActive("Produtos");
          }
        } else {
          const itemRouteAffiliation = routesSales.value.find(
            (item) => item.route === "Affiliation"
          );
          if (itemRouteAffiliation) {
            setSubItemActive(itemRouteAffiliation);
            setItemActive("Vendas");
          }
        }
        return;
      }

      setItemActive(route.item, false);
      setSubItemActive(route);
      assignValueInArray(openItems, false);

      if (route.active) {
        assignValueInArray(openItems, false, false, route.item);
      }

      route.active = true;
    }
  });
}

function getComputedStyleValue(element, property) {
  const computedStyle = window.getComputedStyle(element);
  return parseFloat(computedStyle.getPropertyValue(property)) || 0;
}

function updateHeaderMeasurements() {
  const element = headerElement.value;
  if (element) {
    headerHeight.value = element.getBoundingClientRect().height;
    headerClientHeight.value = element.clientHeight;
    headerMarginTop.value = getComputedStyleValue(element, "margin-top");
    headerMarginBottom.value = getComputedStyleValue(element, "margin-bottom");
  }
}

function handleMouseMove(event) {
  const sidebarElement = GrSidebarRef.value;

  if (sidebarElement && isEventInSideSidebar(event, sidebarElement)) {
    hoverInSideBar.value = true;
  } else {
    hoverInSideBar.value = false;

    if (browsingByKey.value) {
      browsingByKey.value = false;
      hovered.value = false;
      activeSidebar.value = false;

      for (const key in openItems.value) {
        if (Object.prototype.hasOwnProperty.call(openItems.value, key)) {
          if (openItems.value[key]) {
            openItems.value[key] = false;
          }
        }
      }
    }
  }
}

function isEventInSideSidebar(event, sidebarElement) {
  let target = event.target;
  while (target) {
    if (target === sidebarElement) {
      return true;
    }
    target = target.parentElement;
  }
  return false;
}

function checkIfMobile() {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
}

function onResizeHeigth() {
  const element = headerElement.value;
  if (element) {
    headerClientHeight.value = element.clientHeight;
  }
  return headerClientHeight.value + 1;
}

onUnmounted(() => {
  window.removeEventListener("resize", checkIfMobile);
});

onMounted(async () => {
  await nextTick();
  await fetchPermissions();
  await getPermissions();
  requestAnimationFrame(updateHeaderMeasurements);
  initializeActiveState();
  window.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("resize", onResizeHeigth);
});

provide("fetchPermissions", fetchPermissions);
provide("AllRoutesSidebar", ref(allRoutes));
</script>
<template>
  <div>
    <GrSidebar>
      <GrSideHeader
        ref="GrSideHeaderRef"
        :isAmbassador="!!store.state.user.user.is_ambassador"
      >
        <template v-slot:left>
          <GrSidebarHeaderLeft @click="toogleSide" />
        </template>
        <template v-slot:right>
          <GrSidebarHeaderRight />
        </template>
      </GrSideHeader>
      <section
        ref="GrSidebarRef"
        :class="{ activeSidebar: activeSidebar }"
        :style="{ top: distanceFromTop }"
        @mouseleave="closeSideMenu"
        @mouseenter="openSideMenu"
      >
        <GrSideItem
          v-if="
            !store.getters.setPanel || (store.getters.setPanel && dashboard)
          "
          name="Dashboard"
          route="Dashboard"
          :active="activeDashboard"
          :hover="hovered"
          @click="
            setItemActive,
              setSubItemActive({
                name: 'Dashboard',
                route: 'Dashboard',
                active: false,
                item: 'Dashboard',
              })
          "
        >
          <iconSideDashboard class="icons-dropdown" :active="activeDashboard" />
        </GrSideItem>
        <GrSideItemDrop
          v-if="!isClient && !isEmptySales"
          name="Vendas"
          traduction="side.text_1952"
          :active="activeItems.Vendas"
          :open="openItems.Vendas"
          :routes="routesSales"
          :hover="hovered"
          @changeItem="setItemActive($event)"
          @changeSubItem="setSubItemActive"
        >
          <iconSideSales
            :active="isActiveDashboard"
            :open="openItems.Vendas"
            class="icons-dropdown"
          />
        </GrSideItemDrop>
        <GrSideItemDrop
          v-if="!isClient && !isEmptyProducts"
          name="Produtos"
          traduction="side.text_1954"
          :active="activeItems.Produtos"
          :open="openItems.Produtos"
          :routes="routesProducts"
          :hover="hovered"
          @changeItem="setItemActive($event)"
          @changeSubItem="setSubItemActive"
        >
          <iconSideProducts
            class="icons-dropdown"
            :active="activeItems.Produtos"
            :open="openItems.Produtos"
          />
        </GrSideItemDrop>
        <GrSideItemDrop
          v-if="!isClient && !isEmptyFinancial"
          name="Financeiro"
          traduction="side.text_1988"
          :active="activeItems.Financeiro"
          :open="openItems.Financeiro"
          :routes="routesFinancial"
          :hover="hovered"
          @changeItem="setItemActive"
          @changeSubItem="setSubItemActive"
        >
          <iconSideFinancial
            class="icons-dropdown"
            :active="activeItems.Financeiro"
            :open="openItems.Financeiro"
          />
        </GrSideItemDrop>
        <GrSideItem
          v-if="!isClient && !store.getters.setPanel"
          name="Relatórios"
          traduction="side.text_1989"
          route="reports"
          :active="activeRelatorio"
          :hover="hovered"
          @click="
            setItemActive,
              setSubItemActive({
                name: 'Relatórios',
                route: 'reports',
                active: false,
                item: 'Relatórios',
              })
          "
        >
          <iconSideReports class="icons-dropdown" :active="activeRelatorio" />
        </GrSideItem>
        <GrSideItemDrop
          v-if="!isClient && !isEmptyRewards && !store.getters.setPanel"
          name="Recompensas"
          traduction="side.text_2003"
          :active="activeItems.Recompensas"
          :open="openItems.Recompensas"
          :routes="routesRewards"
          :hover="hovered"
          @changeItem="setItemActive"
          @changeSubItem="setSubItemActive"
        >
          <iconSideRewards
            class="icons-dropdown"
            :active="activeItems.Recompensas"
            :open="openItems.Recompensas"
          />
        </GrSideItemDrop>
        <GrSideItemDrop
          v-if="!isClient && !isEmptySystem && !store.getters.setPanel"
          name="Sistema"
          class="last-icon-padding-bottom"
          traduction="side.text_1990"
          :active="activeItems.Sistema"
          :open="openItems.Sistema"
          :routes="routesSystem"
          :hover="hovered"
          @changeItem="setItemActive"
          @changeSubItem="setSubItemActive"
        >
          <iconSideSystem
            class="icons-dropdown"
            :active="activeItems.Sistema"
            :open="openItems.Sistema"
          />
        </GrSideItemDrop>
      </section>
    </GrSidebar>
    <main class="main" :class="{ mobile: isMobile }">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
    <div class="hide-sidebar-mobile" @click="toogleSide()" />
  </div>
</template>

<style scoped lang="scss">
#app:has(.activeSidebar) {
  .mobile-menu-toggle {
    background-color: var(--primary-50) !important;
  }
  .hide-sidebar-mobile {
    @media (max-width: 768px) {
      display: block;
    }
  }
}

.hide-sidebar-mobile {
  display: none;
  height: 100dvh;
  width: 100dvw;
  position: absolute;
  top: 0;
  left: 0;
}

section {
  position: fixed;
  top: 0;
  z-index: 1001;
  background: var(--gray-white);
  height: calc(100vh - 60px);
  width: var(--font-7-8xl);
  max-width: var(--font-11xl);
  transition: var(--transition);
  padding: var(--spacing-5);
  border-right: 1px solid var(--gray-50);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  overflow-y: scroll;
  overflow-x: hidden;

  &:hover,
  &.activeSidebar {
    width: var(--font-11xl);
  }
  @media (max-width: 768px) {
    width: var(--font-11xl);
    margin-left: calc(var(--font-11xl) * -1);
    &.activeSidebar {
      margin-left: 0;
    }
  }
}

main {
  position: relative;
  overflow: hidden;
  width: calc(100dvw - var(--font-9xl));
  height: calc(100dvh - v-bind(distanceFromTop));
  max-height: calc(100dvh - v-bind(distanceFromTop));
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 100px;
  padding: var(--spacing-0) var(--spacing-10);
  display: flex;
  justify-content: center;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  @media (max-width: 768px) {
    left: 0px;
    width: 100vw;
  }
  > div {
    max-width: 1150px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
.icons-dropdown {
  position: relative;
  top: -2px;
  right: 2px;
  width: 20px;
  height: 20px;
  color: #666d6d;
}
.last-icon-padding-bottom {
  padding-bottom: 40px;
}
</style>
