import { render, staticRenderFns } from "./CollapsibleItem.vue?vue&type=template&id=184250fc&scoped=true"
import script from "./CollapsibleItem.vue?vue&type=script&setup=true&lang=js"
export * from "./CollapsibleItem.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CollapsibleItem.vue?vue&type=style&index=0&id=184250fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184250fc",
  null
  
)

export default component.exports